import React, {Fragment, useState, useMemo} from 'react';
import * as Ant from 'antd';
import {
  getOrderType,
  parseCartData,
  getPaymentType,
  getDisplayState,
} from '../Utils/OrderUtil';
import Shortcut from './OrderTable.Shortcut';
import OrderStatusTag from '../Widgets/OrderStatusTag';
import Tag from '../Widgets/Tag';
import {
  ORDER_STATE,
  ORDER_STATUS_COLORS,
  PAYMENT_STATUS,
  PAYMENT_STATUS_COLORS,
  DELIVERY_TYPE,
} from '../dictionary';
import {OpenInNew} from '@styled-icons/material/OpenInNew';
import IconButton from '../Widgets/IconButton';
import Row from '../Widgets/Row';
import {Edit} from '@styled-icons/boxicons-solid/Edit';
import OrderItemShortcutDialog from '../Dialogs/OrderItemShortcutDialog';
import Anchor from '../Widgets/Anchor';

const TableTypes = {
  NORMAL: 0,
  MONTHLY: 1,
  REVIEW: 2,
  PRODUCTION: 3,
};

export default function OrderTable(props) {
  const {
    orders,
    loading,
    type = TableTypes.NORMAL,
    pagination,
    onPaging = () => {},
    onUpdate = () => {},
  } = props;
  const [selected, setSelected] = useState();

  const columns = useMemo(() => {
    return {
      [TableTypes.NORMAL]: [
        {
          title: '訂單編號/藍新單號',
          render: (_, instance) => (
            <div>
              <Anchor to={`/order/?id=${instance.id}`}>
                {instance.display_id}
              </Anchor>
              <div style={{fontSize: 12, color: '#aaa'}}>
                {instance.payment_transaction_id}
              </div>
            </div>
          ),
          width: 170,
          fixed: 'left',
        },
        {
          title: '顧客姓名',
          render: (_, order) => (
            <Anchor to={`/member/?id=${order.buyer_id}`}>
              {order.receiver_name || '-'}
            </Anchor>
          ),
          width: 100,
        },
        {
          title: '訂單狀態',
          render: (_, order) => (
            <OrderStatusTag
              color={ORDER_STATUS_COLORS[order.display_state]}
              isVoid={order.voided}>
              {ORDER_STATE[order.display_state]}
            </OrderStatusTag>
          ),
          width: 120,
        },
        {
          title: '付款狀態',
          render: (order) => (
            <Tag color={PAYMENT_STATUS_COLORS[order.payment_status]}>
              {PAYMENT_STATUS[order.payment_status]}
            </Tag>
          ),
          width: 100,
        },
        {
          title: '付款方式',
          render: (_, order) => {
            let result = getPaymentType(order);
            if (
              order.payment_type === 'offline' &&
              order.payment_status !== 'success' &&
              !!order.remittance_account
            ) {
              result = result + ' ✅';
            }
            return result;
          },
          width: 100,
        },
        {
          title: '運送方式',
          render: (_, order) => {
            let cart = parseCartData(order);
            let logistic = order.logistics?.[0];
            if (logistic) {
              return DELIVERY_TYPE[logistic.logistics_type] || '---';
            }
            if (cart && cart.config.deliveryConfig) {
              return (
                DELIVERY_TYPE[cart.config.deliveryConfig.delivery_type] || '---'
              );
            }
            return '---';
          },
          width: 100,
        },
        {
          title: '總價',
          dataIndex: 'amount',
          width: 70,
        },
        {
          title: '訂單類型',
          render: (_, order) => getOrderType(order.order_type),
          width: 100,
        },
        {
          title: '建立時間',
          render: (_, order) =>
            ` ${order.created.slice(0, 10)} ${order.created.slice(11, 16)}`,
          width: 120,
        },
      ],
      [TableTypes.MONTHLY]: [
        {
          title: '建立時間',
          render: (_, order) =>
            ` ${order.created.slice(0, 10)} ${order.created.slice(11, 16)}`,
          fixed: 'left',
        },
        {
          title: '訂單編號',
          render: (_, order) => (
            <Anchor to={`/order/?id=${order.id}`}>{order.display_id}</Anchor>
          ),
        },
        {
          title: '顧客姓名',
          render: (_, order) => order.receiver_name || '-',
        },
        {
          title: '訂單類型',
          render: (_, order) => getOrderType(order.order_type),
        },
        {
          title: '付款方式',
          render: (_, order) => getPaymentType(order),
        },
        // {
        //   title: '月結單(測試)',
        //   key: 'monthly_order',
        //   dataIndex: 'monthly_order',
        // },
        {
          title: '訂單狀態',
          render: (_, order) => ORDER_STATE[getDisplayState({order})],
        },

        {
          title: '總價',
          dataIndex: 'amount',
        },
      ],
      [TableTypes.REVIEW]: [
        {
          title: '訂單編號',
          render: (_, instance) => (
            <Row>
              <Anchor
                style={{marginRight: 18}}
                to={`/order/?id=${instance.id}`}>
                {instance.display_id}
              </Anchor>
              {/* <IconButton
                onClick={() =>
                  window.open('/order/?id=' + instance.id, '_blank')
                }>
                <OpenInNew />
              </IconButton> */}
            </Row>
          ),
        },
        {
          title: '顧客姓名',
          render: (_, order) => (
            <Anchor to={`/member/?id=${order.buyer_id}`}>
              {order.receiver_name || '-'}
            </Anchor>
          ),
        },
        {
          title: '訂單狀態',
          render: (_, order) => (
            <OrderStatusTag
              color={ORDER_STATUS_COLORS[order.display_state]}
              isVoid={order.voided}>
              {ORDER_STATE[order.display_state]}
            </OrderStatusTag>
          ),
        },
        {
          title: '稿件數量',
          dataIndex: 'items_count',
        },

        {
          title: '有不合格稿件',
          render: (_, order) => (order.is_item_rejected ? '有' : '--'),
        },
        {
          title: '修改',
          render: (record) => (
            <IconButton onClick={() => setSelected(record)}>
              <Edit />
            </IconButton>
          ),
          width: 60,
        },
        {
          title: '訂單類型',
          render: (_, order) => getOrderType(order.order_type),
        },
        {
          title: '更新時間',
          render: (_, order) =>
            ` ${order.updated.slice(0, 10)} ${order.updated.slice(11, 16)}`,
        },
        {
          title: '急件時間',
          render: (_, order) => order.urgent_time?.slice(0, 10),
          width: 200,
        },
        // {
        //   title: '最後上傳時間',
        //   render: (_, order) =>
        //   ` ${order.last_upload_time?.slice(0, 10)} ${order.last_upload_time?.slice(11, 16)}`,
        //   width: 200,
        // },
        // {
        //   title: '是否急件',
        //   render: (_, order) => order.is_urgent ? '是': '否',
        //   width: 160,
        // },
      ],
      [TableTypes.PRODUCTION]: [
        {
          title: '訂單編號',
          render: (_, instance) => (
            <Row>
              <Anchor
                style={{marginRight: 18}}
                to={`/order/?id=${instance.id}`}>
                {instance.display_id}
              </Anchor>
              {/* <IconButton
                onClick={() =>
                  window.open('/order/?id=' + instance.id, '_blank')
                }>
                <OpenInNew />
              </IconButton> */}
            </Row>
          ),
        },
        {
          title: '顧客姓名',
          render: (_, order) => (
            <Anchor to={`/member/?id=${order.buyer_id}`}>
              {order.receiver_name || '-'}
            </Anchor>
          ),
          width: 160,
        },
        {
          title: '訂單狀態',
          render: (_, order) => (
            <OrderStatusTag
              color={ORDER_STATUS_COLORS[order.display_state]}
              isVoid={order.voided}>
              {ORDER_STATE[order.display_state]}
            </OrderStatusTag>
          ),
          width: 160,
        },
        {
          title: '修改',
          render: (record) => (
            <IconButton onClick={() => setSelected(record)}>
              <Edit />
            </IconButton>
          ),
          width: 60,
        },
        {
          title: '稿件數量',
          dataIndex: 'items_count',
          width: 120,
        },

        {
          title: '更新時間',
          render: (_, order) =>
            ` ${order.updated.slice(0, 10)} ${order.updated.slice(11, 16)}`,
          width: 160,
        },
        {
          title: '急件時間',
          render: (_, order) => order.urgent_time?.slice(0, 10),
          width: 160,
        },
      ],
    }[type];
  }, [type]);

  const expandable = useMemo(
    () => ({
      expandedRowRender:
        type === TableTypes.PRODUCTION
          ? (record) => (
              <Shortcut
                instance={record}
                type="production"
                onUpdate={onUpdate}
              />
            )
          : false,
      expandRowByClick: true,
      expandedRowClassName: (record, index, indent) =>
        'production-order-expand',
    }),
    [type, onUpdate],
  );

  return (
    <React.Fragment>
      <Ant.Table
        loading={loading}
        columns={columns}
        dataSource={orders}
        rowKey={(order) => order.id}
        pagination={pagination}
        onChange={(pagination, filters, sorter) => onPaging(pagination)}
        expandable={expandable.expandedRowRender && expandable}
      />
      <OrderItemShortcutDialog
        order={selected}
        visible={!!selected}
        onClose={() => setSelected(null)}
        onUpdate={onUpdate}
      />
    </React.Fragment>
  );
}

export {TableTypes};
