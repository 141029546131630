import React, {useCallback, useRef, useState} from 'react';
import {Modal, Table, message, Select} from 'antd';
import {useOutlet} from 'reconnect.js';
import styled from 'styled-components';
import {PRODUCTION_STATUS, PRODUCTION_STATUS_COLORS} from '../dictionary';
import Tag from '../Widgets/Tag';

const appConfig = require('../data.json');

export default function Shortcut({instance, type, onUpdate}) {
  const [selected, setSelected] = useState();
  const [confirming, setConfirming] = useState(false);
  const [loading, setLoading] = useOutlet('loading');
  const [actions] = useOutlet('actions');

  const columns_production_nest = useRef([
    {
      title: '',
      key: 'blank-1',
      render: () => null,
      width: 49,
    },
    {
      title: '稿件編號',
      render: (item) => `# ${item.id}`,
    },
    {
      title: '',
      key: 'blank-2',
      render: () => null,
      width: 160,
    },
    {
      title: '印製狀態',
      render: (item) => (
        <Tag color={PRODUCTION_STATUS_COLORS[item.production_status]}>
          {PRODUCTION_STATUS[item.production_status]}
        </Tag>
      ),

      width: 160,
    },
    {
      title: '',
      key: 'blank-3',
      render: () => null,
      width: 60,
    },
    {
      title: '廠商',
      render: (item) =>
        item.supplier_data ? item.supplier_data.name : '尚未指派廠商',
      width: 120,
    },
    {
      title: '修改狀態',
      render: (item) => (
        <Select
          value={item.production_status}
          onChange={(value) => {
            setSelected({...item, production_status: value});
            setConfirming(true);
          }}
          style={{width: 120}}>
          {Object.keys(PRODUCTION_STATUS)
            .filter((key) => !['all', 'pending'].includes(key))
            .map((key, idx, arr) => (
              <Select.Option
                key={key}
                value={key}
                disabled={idx < arr.indexOf(item.production_status)}>
                {PRODUCTION_STATUS[key]}
              </Select.Option>
            ))}
        </Select>
      ),
      width: 160,
    },
    {
      title: '',
      key: 'blank-4',
      render: () => null,
      width: 160,
    },
  ]).current;

  const submit = useCallback(async ({id, production_status}) => {
    setLoading(true);
    try {
      await actions.editOrderItem({id, production_status});
      message.success('儲存成功');
      onUpdate();
      setConfirming(false);
      setSelected(null);
    } catch (err) {
      message.error('儲存錯誤');
    }

    setLoading(false);
  }, []);

  return (
    <Wrapper>
      <Table
        columns={columns_production_nest}
        dataSource={instance.order_items}
        rowKey="id"
        pagination={false}
        size="small"
        style={{}}
      />
      <ConfirmModal
        visible={confirming}
        loading={loading}
        onCancel={() => {
          setConfirming(false);
          setSelected(null);
        }}
        item={selected}
        onSubmit={() => submit(selected)}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .ant-table {
    background-color: #fbfbfb;
  }
  & .ant-table-small .ant-table-thead > tr > th {
    color: #aaa;
    font-size: 12px;
    border: 0;
    padding: 8px 16px;
  }
  & .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 8px 16px;
  }
`;

function ConfirmModal({visible, onCancel, onSubmit, item, loading}) {
  return (
    <Modal
      title="確認修改印製狀態？"
      visible={visible}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={onSubmit}
      cancelText="取消"
      okText="確定">
      <div>
        稿件 <span style={{color: '#999'}}> # {item?.id}</span>
      </div>
      <div>
        狀態修改成{' '}
        <span style={{color: '#999'}}>
          {PRODUCTION_STATUS[item?.production_status]?.display || '-'}
        </span>
      </div>
      <div>修改後無法回復前ㄧ步驟狀態，避免訂單狀態錯亂</div>
      <div style={{color: appConfig.colors.error}}>請確認是否更改此狀態？ </div>
    </Modal>
  );
}

export {ConfirmModal};
